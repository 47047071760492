exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-showcase-components-carousels-tsx": () => import("./../../../src/pages/showcase/components/carousels.tsx" /* webpackChunkName: "component---src-pages-showcase-components-carousels-tsx" */),
  "component---src-pages-showcase-components-data-visualizer-tsx": () => import("./../../../src/pages/showcase/components/data-visualizer.tsx" /* webpackChunkName: "component---src-pages-showcase-components-data-visualizer-tsx" */),
  "component---src-pages-showcase-components-index-tsx": () => import("./../../../src/pages/showcase/components/index.tsx" /* webpackChunkName: "component---src-pages-showcase-components-index-tsx" */),
  "component---src-pages-showcase-components-marquee-tsx": () => import("./../../../src/pages/showcase/components/marquee.tsx" /* webpackChunkName: "component---src-pages-showcase-components-marquee-tsx" */),
  "component---src-pages-showcase-index-tsx": () => import("./../../../src/pages/showcase/index.tsx" /* webpackChunkName: "component---src-pages-showcase-index-tsx" */)
}

